import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "tukon-a1e34.firebaseapp.com",
  projectId: "tukon-a1e34",
  storageBucket: "tukon-a1e34.appspot.com",
  messagingSenderId: "991761113922",
  appId: "1:991761113922:web:94d92a6639673bb9b79edb"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
