import "./login.scss"
import React, { useContext, useEffect, useState } from 'react'
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth, db } from "../../firebase.js";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import tukonlogo from "../../assets/tukonlogo2.jpeg"
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";


const Login = () => {

  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userdata, setUserData] = useState({});

  const navigate = useNavigate();

  const currentUser = useContext(AuthContext);

  // console.log(currentUser);

  const handleLogout = (e) => {
    e.preventDefault();

    // console.log(currentUser);

    signOut(auth).then(() => {
      // Sign-out successful.
          navigate("/login")
      }).catch((error) => {
      // An error happened.
      setError(true)
      // console.log(error);
      });
      // console.log(currentUser);
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const {user} = await signInWithEmailAndPassword(auth, email, password);
      setUserData(user);

      const docRef = doc(db, "admins", user.uid);
      const docSnap = await getDoc(docRef);
      // console.log(docSnap.data());

        if(docSnap.data().type === "external") {
          navigate("/home");
        }
        // county admins = county admin  dashboard
        else if(docSnap.data().type === "countyadmin") {
          // navigate("/countyadmindash");
          setError('Access Denied');
          signOut(auth);
        }
        // ntsa & external = external admin dashboard = main admin dashboard but without write routes
        else if (docSnap.data().type === "admin") {
          setError("Access Denied");
          signOut(auth);
        }
        else {
          setError('Access Denied');
          signOut(auth);
        }
    } catch (error) {
      // add error handling here
      // console.log(error);
      setError("");
      switch (error.code) {
        case 'auth/user-not-found':
            setError("User not found");
            break;
        case 'auth/invalid-email':
            setError("Invalid email");
            break;
        default:
            break;
      }
    }

  }

  return (
    <div className="login flex flex-col">
      <img src={tukonlogo} alt="" width={150} height={150} className="mb-5"/>
      <h1>Admin Panel</h1>
      <form onSubmit={handleLogin}>
        <input type="email" placeholder="Email" onChange={e=>setEmail(e.target.value)} className='shadow border rounded px-3 py-5 w-full focus:outline-none focus:shadow-outline'/>
        <input type="password" placeholder="Password" onChange={e=>setPassword(e.target.value)} className='shadow border rounded px-3 py-5 w-full focus:outline-none focus:shadow-outline mb-5'/>
        <button type="submit">Login</button>
        {error && <span>{error}</span>}
      </form>
    </div>
  )
}

export default Login